/* eslint-disable react/jsx-props-no-spreading */
import { FormEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';

import {
  Box,
  Button,
  ButtonGroup,
  Label,
  MultiValue,
  Select,
  Text,
  TextArea,
  TextField,
} from '@nike/eds';
import { useDebounce, useOktaUsers } from '@nike.innovation/ui-components';

import { Member, Project, useApiClient } from './core/use-api-client';
import { environment } from '../environments/environment';

import styles from './new-project-page.module.css';

const emptyProject = {
  id: '',
  name: '',
  description: '',
  coaches: [],
  cohort: 2023,
  members: [],
  tags: [],
  assets: [],
};

export function NewProjectPage() {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [project, setProject] = useState<Project>(emptyProject);

  const [userStartsWith, setUserStartsWith] = useState('');
  const debouncedUserStartsWith = useDebounce(userStartsWith, 500);

  const { oktaUsers, isUsersLoading } = useOktaUsers(
    environment.aegisBaseUrl,
    debouncedUserStartsWith
  );

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpeg', '.jpg'],
    },
  });

  const onSubmit = async (event: FormEvent<HTMLFormElement>, newProject: Project) => {
    event.preventDefault();
    event.stopPropagation();

    await apiClient.createProject(newProject);
    navigate('/');
  };

  return (
    <Box>
      <Text font="title-1" as="h1" className="eds-spacing--mb-32">
        Create new project
      </Text>

      <form onSubmit={event => onSubmit(event, project)}>
        <Box className="eds-flex eds-flex--direction-column eds-gap--32">
          <TextField
            id="name"
            label="Project Name"
            type="text"
            required
            onChange={e => {
              setProject(previousProject => ({
                ...previousProject,
                name: e.target.value,
              }));
            }}
          />

          <TextArea
            id="description"
            label="Description"
            minRows={2}
            required
            onChange={e => {
              setProject(previousProject => ({
                ...previousProject,
                description: e.target.value,
              }));
            }}
          />

          <Box>
            <Select
              id="okta-users"
              label="Add collaborators"
              isMulti
              isLoading={isUsersLoading}
              onInputChange={(value: string) => setUserStartsWith(value)}
              onChange={(values: MultiValue<{ label: string; value: Member }>) =>
                setProject(previousProject => ({
                  ...previousProject,
                  members: values.map(({ value }) => value),
                }))
              }
              options={oktaUsers.items.map(
                (user: { email: string; givenName: string; surname: string }) => ({
                  value: {
                    firstName: user.givenName,
                    lastName: user.surname,
                    email: user.email,
                  },
                  label: user.email,
                })
              )}
              noOptionsMessage={() => 'No user starts with that email'}
              placeholder="Begin typing user's email"
              required={false}
              value={project.members.map(member => ({ value: member, label: member.email }))}
            />
          </Box>

          {/* <Select
            id="tags"
            label="Tags"
            options={[{ value: '', label: '' }]}
            defaultValue={{ value: '', label: '' }}
            isMulti
            required
          /> */}

          <Box>
            <Label font="title-6" htmlFor="cover-image" className="eds-spacing--mb-16">
              Cover image
            </Label>

            <Box
              {...getRootProps()}
              className={classnames(styles.dropzone, { [styles.dragActive]: isDragActive })}
            >
              <input {...getInputProps()} id="cover-image" />

              <Text font="body-2">Click here or drop an image</Text>
            </Box>

            {acceptedFiles.map(file => (
              <Text font="body-2" key={file.name}>
                {file.name}
              </Text>
            ))}
          </Box>

          <ButtonGroup>
            <Button type="submit" onClick={async (event: any) => onSubmit(event, project)}>
              Create
            </Button>
          </ButtonGroup>
        </Box>
      </form>
    </Box>
  );
}
