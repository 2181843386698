import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { NikeApp } from '@nike/nike-design-system-icons';
import { AppHeader, Avatar, Box, Spinner, Text } from '@nike/eds';
import { RequiresAuth } from '@nike.innovation/auth-guard';

import { environment } from '../environments/environment';
import { HomePage } from './home-page';
import { NewProjectPage } from './new-project-page';
import { ProjectPage } from './project-page';

import '@nike/eds/dist/index.css';
import '../assets/fonts.css';
import './global.css';

import styles from './app.module.css';

const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  tokenManager: {
    autoRenew: true,
  },
  pkce: true,
});

export function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Box className={styles.content}>
        <AppHeader
          avatarSlot={<Avatar initials="dg" size={36} />}
          appName={<Link to="/">Winter Hours</Link>}
          toolbarSlot={<Text>menu</Text>}
          logoSlot={
            <Link to="/">
              <NikeApp width="56px" height="64px" />
            </Link>
          }
        />

        <main className={styles.main}>
          <Routes>
            <Route
              path="implicit/callback"
              element={<LoginCallback loadingElement={<Spinner />} />}
            />

            <Route path="" element={<RequiresAuth loadingElement={<Spinner />} />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/projects/new" element={<NewProjectPage />} />
              <Route path="/projects/:id" element={<ProjectPage />} />
            </Route>
          </Routes>
        </main>
      </Box>
    </Security>
  );
}
