import { Link } from 'react-router-dom';

import { Avatar, Box, Card, Text } from '@nike/eds';
import { Project } from './use-api-client';
import { getInitials } from './name-utils';

export function ProjectsGrid({ projects }: { projects: Project[] }) {
  return (
    <Box className="eds-grid eds-grid--l-cols-3 eds-grid--m-cols-2">
      {projects.map(project => (
        <Card
          key={project.id}
          className="eds-elevation--1"
          style={{ padding: 0, overflow: 'hidden' }}
        >
          <img
            className="eds-spacing--mb-4"
            alt="FPO"
            src={`/assets/${project.cohort}/${project.assets[0]}`}
          />

          <Box className="eds-spacing--m-12">
            <Link to={`/projects/${project.id}`}>
              <Text font="title-3" as="h2" className="eds-spacing--mb-12">
                {project.name}
              </Text>
            </Link>

            <Text font="body-3" className="eds-spacing--mb-12">
              {project.description}
            </Text>

            <Box className="eds-flex eds-gap--8">
              {project.members.map(member => (
                <Avatar initials={getInitials(member)} key={member.email} />
              ))}
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );
}
