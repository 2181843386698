import useSWR from 'swr';

import { Loading } from '@nike/nike-design-system-icons';
import { Box, Text } from '@nike/eds';

import { ProjectsGrid } from './core/projects-grid';
import { useApiClient } from './core/use-api-client';

export function HomePage() {
  const apiClient = useApiClient();
  const { data: projects } = useSWR('/projects', apiClient.getProjects);

  return (
    <Box>
      <Text font="title-1" className="eds-spacing--mb-24">
        Winter Hours 2020
      </Text>

      {projects ? <ProjectsGrid projects={projects} /> : <Loading />}
    </Box>
  );
}
