import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';

import { environment } from '../../environments/environment';

export interface Member {
  firstName: string;
  lastName: string;
  email: string;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  cohort: number;
  tags: string[];
  members: Member[];
  coaches: Member[];
  assets: string[];

  updatedAt?: number;
  createdAt?: number;
}

export function useApiClient() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  };

  return {
    getProjects: async (): Promise<Project[]> => {
      const { data } = await axios.get<Project[]>(
        `${environment.apiBaseUrl}/api/v1/projects`,
        axiosConfig
      );

      return data;
    },
    createProject: (project: Project) =>
      axios.post(`${environment.apiBaseUrl}/api/v1/projects`, project, axiosConfig),
  };
}
