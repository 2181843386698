// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  clientId: 'nike.innovation.projects-db',
  issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  apiBaseUrl: 'https://winter-hours-api.innovation-test.nike.io',
  aegisBaseUrl: 'https://api.aegis.nikecloud.com/v1/qa',
};
