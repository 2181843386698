import useSWR from 'swr';
import { Link, useParams } from 'react-router-dom';

import { Avatar, Box, Text } from '@nike/eds';
import { Loading } from '@nike/nike-design-system-icons';

import { useApiClient } from './core/use-api-client';
import { getInitials } from './core/name-utils';

export function ProjectPage() {
  const { id } = useParams();
  const apiClient = useApiClient();

  if (!id) {
    throw new Error('id is undefined');
  }

  const { data: projects } = useSWR('/projects', apiClient.getProjects);
  const project = projects?.find(p => p.id === id);

  return project ? (
    <Box>
      <Text font="title-1" className="eds-spacing--mb-8">
        {project.name}
      </Text>

      <Text font="subtitle-1" role="doc-subtitle" className="eds-spacing--mb-24">
        {`Winter Hours ${project.cohort}`}
      </Text>

      <Text font="body-1" className="eds-spacing--mb-24">
        {project.description}
      </Text>

      <Box className="eds-flex eds-gap--16 eds-spacing--mb-48">
        {project.tags.map(tag => (
          <Link to={`/projects?tag=${tag}`} key={tag}>
            <Text font="body-3">{tag}</Text>
          </Link>
        ))}
      </Box>

      <Box className="eds-spacing--mb-48">
        <Text font="title-4" as="h2" className="eds-spacing--mb-24">
          Members
        </Text>

        <Box className="eds-grid eds-grid--m-cols-3 eds-grid--s-cols-2">
          {project.members.map(member => (
            <Box
              key={member.email}
              className="eds-flex eds-flex--direction-row eds-gap--16 eds-flex--align-items-center"
            >
              <Avatar initials={getInitials(member)} />

              <Text font="body-2">{`${member.firstName} ${member.lastName}`}</Text>
            </Box>
          ))}
        </Box>
      </Box>

      {/* <Box className="eds-spacing--mb-48">
        <Text font="title-4" as="h2" className="eds-spacing--mb-24">
          Coaches
        </Text>

        <Box className="eds-grid eds-grid--m-cols-3 eds-grid--s-cols-2">
          {project.coaches.map(coach => (
            <Box
              key={coach.email}
              className="eds-flex eds-flex--direction-row eds-gap--16 eds-flex--align-items-center"
            >
              <Avatar initials={getInitials(coach)} key={coach.email} />

              <Text font="body-2">{coach.email}</Text>
            </Box>
          ))}
        </Box>
      </Box> */}

      <Box className="eds-spacing--mb-48">
        <Text font="title-4" as="h2" className="eds-spacing--mb-24">
          Assets
        </Text>

        <Box className="eds-grid eds-grid--xl-cols-3 eds-grid--m-cols-2 eds-grid--items-align-center">
          {project.assets.map(asset => (
            <img key={asset} alt="FPO" src={`/assets/${project.cohort}/${asset}`} />
          ))}
        </Box>
      </Box>
    </Box>
  ) : (
    <Loading />
  );
}
